import React from 'react';
import Favorites from "../components/Favorites";

const FavoritesPage = () => {
    return (
        <div>
            <Favorites />
        </div>
    );
};

export default FavoritesPage;