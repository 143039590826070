import React, {useEffect} from 'react';
import Swiper from "../components/Swiper";
import Carousel from "../components/Carousel";
import Categories from "../components/Categories";
import {useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import Reviews from "../components/Reviews";

const Index = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`Интернет магазин - АрсеналСтрой`}</title>
                <meta
                    name="description"
                    content="Межкомнатные двери в СПб. Компания ООО АрсеналСтрой с 2008 г. производит межкомнатные двери Арсенал в Санкт-Петербурге и имеет безупречную репутацию."
                />
            </Helmet>
            <Swiper />
            <Carousel />
            <Categories />
            <Reviews />
        </div>
    );
};

export default Index;