import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {fetchDoor} from "../actions/door";
import {sendOrder} from "../actions/order";
import {useCookies} from "react-cookie";

const Modal = ({order, price}) => {
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [cookies, setCookie] = useCookies(['userId']);

    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch({type: 'HIDE_MODAL'})
    }
    const createOrder = (order) => {
        const fieldOrder = {};
        fieldOrder.name = name;
        fieldOrder.phoneNumber = phone;
        fieldOrder.width = order.width.value;
        fieldOrder.height = order.height.value;
        fieldOrder.decor = order.decor.value;
        fieldOrder.price = price;
        fieldOrder.title = order.title;
        fieldOrder.additions = order.additions;
        fieldOrder.amount = order.amount;
        fieldOrder.email = email
        fieldOrder.img = order.img
        fieldOrder.idDoor = order.idDoor
        fieldOrder.guestId = cookies.userId
        dispatch(sendOrder(fieldOrder));
        window.scrollTo(0, 0);
        dispatch({type: 'HIDE_MODAL'});
    }
    return (
        <div className="modal fade show" id="exampleModal-1" tabIndex="-1"
             aria-labelledby="exampleModalLabel" style={{display: 'block', paddingRight: '17px'}}
             aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Заказать</h5>
                        <button onClick={closeModal} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Оставьте свой номер и мы вам перезвоним!
                        </p>
                        <div className="form-group mb-2">
                            <label htmlFor="exampleInput-1">Ваше имя</label>
                            <input value={name} onChange={e => setName(e.target.value)} id="exampleInput-1" className="form-control form-control-sm"
                                   type="text"  />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="exampleInput-1">Ваш номер</label>
                            <input value={phone} onChange={e => setPhone(e.target.value)} id="exampleInput-1" className="form-control form-control-sm"
                                   type="text" />
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="exampleInput-1">Ваш email</label>
                            <input value={email} onChange={e => setEmail(e.target.value)} id="exampleInput-1" className="form-control form-control-sm"
                                   type="text" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="container-fluid">
                            <div className="row gutter-0">
                                <div className="col">
                                    <button onClick={closeModal} type="button" className="btn btn-block btn-secondary"
                                            data-dismiss="modal">Закрыть
                                    </button>
                                </div>
                                <div className="col">
                                    <button onClick={() => {createOrder(order)}} type="button" className="btn btn-block btn-primary">Заказать
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;