export const INDEX_ROUTE = '/'
export const CATALOG_ROUTE = '/doors'
export const CATEGORY_EKOSHPON_LIGHT_ROUTE = '/ekoshpon-light'
export const CATEGORY_3D_ROUTE = '/3D'
export const CATEGORY_EKOSHPON_ROUTE = '/ekoshpon'
export const CATEGORY_WHITE_ROUTE = '/white'
export const ABOUT_ROUTE = '/about'
export const CONTACTS_ROUTE = '/contact'
export const CATEGORY_CLASSIC_ROUTE = '/classic'
export const CATEGORY_EMAL_ROUTE = '/emal'
export const CATEGORY_EMALIT_ROUTE = '/emalit'
export const CATEGORY_SOSNA_ROUTE = '/sosna'
export const CATEGORY_DUB_ROUTE = '/dub'
export const CATEGORY_FURNITURE_DOORHANDLE_ROUTE = '/doorhandle'
export const CATEGORY_FURNITURE_LATCH_ROUTE = '/latch'
export const PORTFOLIO_ROUTE = '/portfolio'
export const SEARCH = '/search'
export const FAVORITES = '/favorites'